import {ChangeEvent, CSSProperties, FocusEvent, forwardRef, KeyboardEvent, useEffect, useState} from 'react';
import * as React from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import classnames from 'classnames';
import moment, {Moment} from 'moment-timezone';

import {generateValidateDateMessage} from '@/utils/datePicker';
import {getDateTimeFormats, isBefore} from '@/utils/timeFormatter';

import {MHRegularButton} from '../buttons';
import MHDatePickerContent from '../MHDatePickerContent/MHDatePickerContent';
import MHTextField from '../MHTextField';

import datePickerStyles from './styles.module.scss';

const {DATE_FORMAT} = getDateTimeFormats();

const shortcutsMap: {[key: string]: () => moment.Moment} = {
    N: () => moment(),
    T: () => moment(),
    'T+1': () => moment().add(1, 'days'),
    'W+1': () => moment().add(14, 'days'),
};

const parseShortcut = (string: string) => (shortcutsMap[string] ? shortcutsMap[string]() : string);

type MHDatePickerProps = {
    name?: string;
    label?: string | React.ReactNode;
    value?: string;
    error?: boolean;
    maxDate?: string;
    minDate?: string;
    focused?: boolean;
    typable?: boolean;
    disabled?: boolean;
    showOnTop?: boolean;
    hideToday?: boolean;
    placeholder?: string;
    inputStyles?: string;
    errorMessage?: string;
    positionLeft?: boolean;
    styles?: CSSProperties;
    disableShortcuts?: boolean;
    showCalendarOuter?: boolean;
    forceChangeOnBlur?: boolean;
    textFieldClassName?: string;
    externalErrorClassName?: string;
    showRequiredAsterisk?: boolean;
    endAdornment?: React.ReactNode;
    blockManualDateChange?: boolean;
    externalCalendarClassName?: string;
    externalContainerClassName?: string;
    onChangeHandler?: (date: string) => void;
    isOutsideRange?: (day: Moment) => boolean;
    inputBaseClasses?: {[key: string]: string};
    setShowCalendarOuter?: (showCalendarOuter: boolean) => void;
    onBlur?: (date?: string, cb?: (newDate: string) => void) => void;
    autoComplete?: string;
    autoCalendarVisible?: boolean;
};

const MHDatePicker = forwardRef(
    (
        {
            name,
            label,
            error,
            onBlur,
            styles,
            maxDate,
            minDate,
            focused,
            disabled,
            showOnTop,
            hideToday,
            value = '',
            inputStyles,
            errorMessage,
            positionLeft,
            endAdornment,
            isOutsideRange,
            typable = true,
            onChangeHandler,
            inputBaseClasses,
            disableShortcuts,
            showCalendarOuter,
            forceChangeOnBlur,
            setShowCalendarOuter,
            showRequiredAsterisk,
            blockManualDateChange,
            textFieldClassName = '',
            placeholder = DATE_FORMAT,
            externalCalendarClassName,
            externalContainerClassName,
            externalErrorClassName,
            autoComplete,
            autoCalendarVisible,
        }: MHDatePickerProps,
        datePickerRef,
    ) => {
        const dateObj = value && minDate && isBefore(moment(value), moment(minDate)) ? moment(minDate) : moment(value);
        const formattedDate = dateObj.isValid() ? dateObj.format(DATE_FORMAT) : '';

        const [isCalendarVisible, setIsCalendarVisible] = useState(false);
        const [date, setDate] = useState(formattedDate);
        const [prevValue, setPrevValue] = useState(formattedDate);

        useEffect(() => {
            setDate(formattedDate);
            setPrevValue(formattedDate);
        }, [formattedDate, value]);

        useEffect(() => {
            /**
             * showCalendarOuter controls calendar view state from outside.
             * after showCalendarOuter is updated to true, it should be refreshed to initial state (false)
             */
            if (showCalendarOuter) {
                setShowCalendarOuter(false);
                setIsCalendarVisible(true);
            }
        }, [setShowCalendarOuter, showCalendarOuter]);

        const handleChange = (value: string) => {
            const isInputValueValid = !generateValidateDateMessage(value, minDate, maxDate);

            if (isInputValueValid && !autoCalendarVisible) {
                setIsCalendarVisible(false);
            }

            if (!blockManualDateChange) {
                setDate(value);
            }

            onChangeHandler(value);
        };

        const handleInputBlur = (value?: string) => {
            if (forceChangeOnBlur && !value && !date) {
                onChangeHandler(value);
            }

            //if calendar is visible we don't need to apply onblur changes
            if (isCalendarVisible && !autoCalendarVisible) return;

            const dateValue = value || date;
            if (disableShortcuts) {
                onBlur &&
                    onBlur(dateValue, (newDate: string) => {
                        setDate(newDate);
                        setPrevValue(newDate);
                    });
            } else {
                let newDate = prevValue;
                const dateObj = moment(
                    parseShortcut(dateValue.toUpperCase()),
                    [
                        'M/D/YYYY',
                        'YYYY-M-D',
                        'D/M/YYYY',
                        'YYYY-D-M',
                        'D M YYYY',
                        'M D YYYY',
                        'M/D/YY',
                        'YY-M-D',
                        'D/M/YY',
                        'YY-D-M',
                        'D M YY',
                        'M D YY',
                    ],
                    true,
                );
                const dateIsValid = dateObj.isValid();

                if (dateIsValid) newDate = dateObj.format(DATE_FORMAT);
                if (!dateValue) newDate = '';

                handleChange(newDate);
                setDate(newDate);
                setPrevValue(newDate);

                onBlur && onBlur();
            }
        };

        const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
            if (event.code === 'Enter' && !autoCalendarVisible) {
                event.preventDefault();
                setIsCalendarVisible((prev) => !prev);
            }

            if (event.code === 'Tab' && !autoCalendarVisible) {
                handleInputBlur();
                setIsCalendarVisible(false);
            }
        };

        const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (!blockManualDateChange) {
                setDate(event.target.value);
            }
        };

        const moveToToday = () => {
            handleChange(moment().format(DATE_FORMAT));
            setIsCalendarVisible(false);
        };

        const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (disabled) {
                return;
            }

            if (!event.target.value) {
                setDate('');
                setPrevValue('');
            }

            handleInputBlur(event.target.value);
        };

        const handleFocus = () => !disabled && setIsCalendarVisible(true);

        const handleClick = () => setIsCalendarVisible(true);

        const handleAction = () => {
            handleInputBlur();
            if (!autoCalendarVisible) {
                setIsCalendarVisible(false);
            }
        };

        return (
            <div
                className={classnames(datePickerStyles.container, {
                    [externalContainerClassName]: externalContainerClassName,
                })}
                onKeyDown={handleKeyDown}
            >
                {typable && (
                    <MHTextField
                        ref={datePickerRef}
                        name={name}
                        inputStyles={inputStyles}
                        showRequiredAsterisk={showRequiredAsterisk}
                        rootClassName={textFieldClassName}
                        inputBaseClasses={inputBaseClasses}
                        disabled={disabled}
                        label={label}
                        value={date}
                        placeholder={placeholder}
                        autoFocus={focused}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        onChange={handleInputChange}
                        error={error}
                        errorMessage={errorMessage}
                        endAdornment={endAdornment}
                        externalErrorClassName={externalErrorClassName}
                        autoComplete={autoComplete}
                    />
                )}

                {!typable && (
                    <MHRegularButton
                        text={value}
                        onClick={handleClick}
                        btnType="secondary-positive"
                        disabled={disabled}
                        btnSize="small"
                        endIcon={<CalendarTodayIcon className={datePickerStyles.calendarIcon} />}
                    />
                )}

                {isCalendarVisible && (
                    <div
                        style={styles}
                        className={classnames(datePickerStyles.commonStyles, {
                            [externalCalendarClassName]: !!externalCalendarClassName,
                            [datePickerStyles.topModalStyles]: showOnTop,
                            [datePickerStyles.leftModalStyles]: positionLeft,
                        })}
                    >
                        <MHDatePickerContent
                            date={date}
                            minDate={minDate}
                            maxDate={maxDate}
                            hideToday={hideToday}
                            action={handleAction}
                            moveToToday={moveToToday}
                            onChangeHandler={handleChange}
                            // @ts-ignore
                            isOutsideRange={isOutsideRange}
                        />
                    </div>
                )}
            </div>
        );
    },
);

(MHDatePicker as React.NamedExoticComponent).displayName = 'MHDatePicker';
export default MHDatePicker;
